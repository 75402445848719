<template>
  <a-modal
    :visible.sync="show"
    title="选择商品"
    destroyOnClose
    width="600px"
    :footer="null"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <div class="flex">
          <a-form-model-item prop="type" label="属性">
            <a-select v-model="searchForm.type" placeholder="产品属性" style="width:110px;"
              :disabled="onlyCake">
              <a-select-option value>全部</a-select-option>
              <a-select-option
                v-for="item in Object.keys(goodsType)"
                :value="item"
                :key="item"
              >{{ goodsType[item] }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="goods_name" label="名称" style="flex:1;">
            <a-input allowClear v-model="searchForm.goods_name" placeholder="请输入商品名称"
                @keyup.enter="handlerSearch"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="status">
            <a-select v-model="searchForm.status" placeholder="产品状态" style="width:80px;"
              @change="handlerSearch">
              <a-select-option :value="Number(1)">正常</a-select-option>
              <a-select-option :value="Number(-1)">禁用</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="ml-2">
            <a-button @click="handlerSearch" type="primary">搜索</a-button>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
    <base-table
      class="goosTable-class"
      ref="goodsTable"
      :columnsData="columns"
      rowKey="goods_id"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :customHeight="300"
      :pageSize.sync="searchForm.page_count"
    >
      <template #operation="{ record }">
        <a-button type="link" class="p-0" @click="handlerSelect(record.goods_id)">选择</a-button>
      </template>
    </base-table>
    
  </a-modal>
</template>

<script>
import { formatGoodsType, goodsType } from "@/utils/type"
import { getGoodsList } from "@/api/goods"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onlyCake: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      goodsType: goodsType,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      searchForm: {
        goods_name: "",
        type: "",
        status: 1,
        page: 1,
        page_count: 30,
      },
      columns: [
        // {
        //   title: "商品编码",
        //   dataIndex: "goods_id",
        //   align: "center",
        // },
        {
          title: "产品属性",
          dataIndex: "type",
          align: "center",
          width: 100,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 80,
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        },
      ],
      tableData: [],
      total: 0,
      rows: [],
    }
  },
  async mounted() {
    if(this.onlyCake){
      this.searchForm.type = "3"
    }
    await this.initData()
  },
  methods: {
    async initData() {
      const { data, code } = await getGoodsList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    // 保存
    handlerSelect(goods_id) {
      this.$emit("ok", goods_id)
      this.$emit("update:show", false)
    },

    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    resetForm() {
      this.searchForm.goods_name = ""
      this.searchForm.type = ""
      this.searchForm.page = 1
      this.$refs.searchRef.resetFields()
      this.initData()
    },

  },
}
</script>

<style lang="less">
.goosTable-class {
  margin-left: -10px;
  .ant-modal-body {
    position: relative;
    padding-bottom: 10px;
  }
}
</style>