<template>
  <div>
    <base-table ref="tableRef"
                :rowKey="rowKey"
                :columnsData="salmodel_columns"
                :tableData="tableData"
                :total="total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #toolbar>
        <div class="flex justify-between">
          <div class="flex">
            <h4 class="text-lg font-bold">预约/预售/限购 管理</h4>
            <a-button type="link" class="p-0 ml-6" 
                @click="handlerSearch">刷新</a-button>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div>
            <a-tabs type="card"
                    class="w-full"
                    @change="handlerTypeChange"
                    :activeKey="activeTabKey">
              <a-tab-pane v-for="item in saleModelList"
                          :key="item.id"
                          :tab="item.name"></a-tab-pane>
              <a-tab-pane key="99" tab="限购"></a-tab-pane>
              <a-tab-pane key="98" tab="组合装"></a-tab-pane>
              <a-tab-pane key="97" tab="爆款活动蛋糕"></a-tab-pane>
              <template #tabBarExtraContent>
                <a-radio-group class="ml-2" v-model="searchForm.status" @change="handlerSearch">
                  <a-radio value="1">正常商品</a-radio>
                  <a-radio value="-1">禁用商品</a-radio>
                </a-radio-group>
              </template>
            </a-tabs>
          </div>
          <a-button type="link" class="p-0"
                @click="handlerSelect">添加商品</a-button>
        </div>
      </template>
      <template #operation="{ record }">
        <a-button v-if="activeTabKey==97" type="link" class="p-0"
            @click="handlerDeleteActCake(record.goods_id)">取消爆款</a-button>
        <a-button v-else type="link" class="p-0"
            @click="handlerEditModel(record.goods_id)">修改模式</a-button>
      </template>
    </base-table>

    <!-- 编辑售卖模式 -->
    <sale-model-modal 
        v-if="isShowSaleModel"
        :show.sync="isShowSaleModel"
        :goodsId="activeGoodsId"
        @ok="onSaleModelChanged" />
    <!-- 添加预约/预售商品 -->
    <add-goods-single
        v-if="isShowAddGoods"
        :onlyCake="activeTabKey == 97"
        :show.sync="isShowAddGoods"
        @ok="handlerSelectOk" />

  </div>
</template>

<script>
import { 
  getGoodsList, 
  updateGoodsStatus,
  setGoodsDetailEnter
} from "@/api/goods";
import pageData from "./columns";
import { 
  checkAuth
} from '@/utils/authData.js'

import { saleModel, formatSaleModel } from "@/utils/type";
import SaleModelModal from './components/sale-model-modal.vue'
import addGoodsSingle from "@/components/select-modal/add-goods-single.vue"

export default {
  components: { SaleModelModal, addGoodsSingle },
  data() {
    return {
      ...pageData,
      saleModelList: [],
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      hasAuthStatus: checkAuth("goods:update_status"),

      total: 0,
      tableData: [],
      activeGoodsId: null,

      activeTabKey: "1",
      searchForm: {
        sale_model: "",
        is_restricted: "",
        is_outlets_city_day_stock: "",
        status: "1",
        page: 1,
        page_count: 20,
      },

      isShowSaleModel: false,
      isShowAddGoods: false,
    };
  },
  mounted() {
    const list = Object.keys(saleModel).filter(id=>id!=0).map(val=>{
      return {
        id: val,
        name: formatSaleModel(val)
      }
    })
    this.saleModelList = list

    this.searchForm.sale_model = this.saleModelList[0].id
    this.handlerSearch()
  },
  methods: {
    async initData() {
      if(this.loading) return
      this.tableData = []
      this.total = 0
      this.loading = true
      const { data, code } = await getGoodsList(this.searchForm);
      this.loading = false
      if (code == 0) {
        this.tableData = data.list.map(el=>{
          const sale_model_desc = []
          if(el.sale_model == 1){
            sale_model_desc.push(el.sale_start_time.substr(0,16)+"开售")
          }
          if(el.sale_model == 2){
            if(el.delivery_start_time && el.delivery_end_time){
              sale_model_desc.push(el.delivery_start_time.substr(0,10)+"~"+el.delivery_end_time.substr(0,10)+"发货")
            }else if(el.delivery_end_time){
              sale_model_desc.push(el.delivery_end_time.substr(0,10)+"前发货")
            }else if(el.delivery_start_time){
              sale_model_desc.push(el.delivery_start_time.substr(0,10)+"后发货")
            }
          }
          if(el.sale_model == 3){
            if(el.sale_start_time){
              sale_model_desc.push(el.sale_start_time.substr(0,10)+"~"+el.sale_end_time.substr(0,10)+"售卖")
            }
            if(el.delivery_start_time){
              sale_model_desc.push(el.delivery_start_time.substr(0,10)+"~"+el.delivery_end_time.substr(0,10)+"发货")
            }
          }
          if(el.restricted_num){
            if(el.restricted_mode == 2){
              sale_model_desc.push("每人每日限购"+el.restricted_num+"个")
            }else{
              sale_model_desc.push("每人限购"+el.restricted_num+"个")
            }
          }
          if(el.least_buy_num){
            sale_model_desc.push(el.least_buy_num+"个起购")
          }
          el.sale_model_desc = sale_model_desc.join("，")
          
          return el
        });
        this.total = data.total_count
      }
    },
    handlerSearch(){
      this.searchForm.page = 1
      this.total = 0
      this.initData()
    },

    // 设定售卖模式
    handlerEditModel(goods_id) {
      this.activeGoodsId = goods_id
      this.isShowSaleModel = true
    },
    // 取消爆款
    handlerDeleteActCake(goods_id) {
      const _this = this
        this.$confirm({
          title: "提示",
          content: "确定把该产品修改为 普通商品 ？",
          okText: "确定",
          okType: "danger",
          cancelText: "取消",
          async onOk() {
            const { code } = await setGoodsDetailEnter({
              goods_id: goods_id,
              is_outlets_city_day_stock: 1,
            })
            if (code == 0) {
              _this.$message.success("已修改")
            }
            _this.initData()
          },
          onCancel() {
            console.log("Cancel")
          },
        })
    },

    // 禁用、恢复
    async handlerStatus(id, status) {
      const { code } = await updateGoodsStatus({
        goods_id: id,
        status: status
      });
      if (code === 0) {
        this.initData();
        if(status == -1){
          this.$message.success("已放入回收站");
        }else{
          this.$message.success("已恢复");
        }
      }
    },

    handlerSelect(){
      this.isShowAddGoods = true
    },
    handlerSelectOk(goods_id){
      if(this.activeTabKey == 97){
        const _this = this
        this.$confirm({
          title: "提示",
          content: "确定设置该产品为 “爆款活动蛋糕” ？",
          okText: "确定",
          okType: "danger",
          cancelText: "取消",
          async onOk() {
            const { code } = await setGoodsDetailEnter({
              goods_id: goods_id,
              is_outlets_city_day_stock: 2,
            })
            if (code == 0) {
              _this.$message.success("设置成功")
            }
            _this.initData()
          },
          onCancel() {
            console.log("Cancel")
          },
        })
      }else{
        this.activeGoodsId = goods_id
        this.isShowSaleModel = true
      }
    },

    handlerTypeChange(val) {
      this.activeTabKey = val
      if(val == "97"){
        this.searchForm.sale_model = ""
        this.searchForm.is_restricted = ""
        this.searchForm.is_least_buy = ""
        this.searchForm.is_outlets_city_day_stock = 2
      }else if(val == "99"){
        this.searchForm.sale_model = ""
        this.searchForm.is_restricted = 2
        this.searchForm.is_least_buy = ""
        this.searchForm.is_outlets_city_day_stock = ""
      }else if(val == "98"){
        this.searchForm.sale_model = ""
        this.searchForm.is_restricted = ""
        this.searchForm.is_least_buy = 2
        this.searchForm.is_outlets_city_day_stock = ""
      }else{
        this.searchForm.sale_model = val
        this.searchForm.is_restricted = ""
        this.searchForm.is_least_buy = ""
        this.searchForm.is_outlets_city_day_stock = ""
      }
      this.handlerSearch()
    },

    onSaleModelChanged(){
      this.initData()
    },

  },
};
</script>

<style lang="less">
</style>
